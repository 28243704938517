<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

//import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  /*setup() {
    const router = useRouter();
    console.log("inside App.vue");
    if (
      localStorage.getItem("userInfo") != null &&
      localStorage.getItem("ricordami") != null
    ) {
      console.log(localStorage.getItem("ricordami"));
      router.replace("/tabs/");
    }
  },*/
});
</script>