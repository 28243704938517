
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCheckbox,
  //IonSpinner,
  IonLoading,
  //toastController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";
import md5 from "md5";
import { useRouter } from "vue-router";

import { openToast } from "../services/toast";
//Set user in indexedDB --> dbUser --> user
import { setUser } from "../services/localbase";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonCheckbox,
    //IonSpinner,
    IonLoading,
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const isSignedIn = ref(false);
    //const userData: string[] = reactive([]);
    const ricordami = ref(false);
    const loading = ref(false);
    //loading spinner
    const setOpen = (state: boolean) => (loading.value = state);

    const router = useRouter();

    //Login function
    function doLogin() {
      //this.loader = true;
      loading.value = true;
      const hashPassword = md5(password.value.toString());

      const data = new FormData();
      data.append("users_email", email.value);
      data.append("users_password", hashPassword.toString());

      axios
        .post("https://crmauxilium.firegui.com/rest/v1/login/users", data, {
          headers: {
            Authorization: "Bearer 26d460b39301781b10d37af31e2e5a4a",
          },
        })
        .then(function (response) {
          // handle success
          if (response.data.status == 0 && response.data.data.length != 0) {
            //userData.push(response.data.data);
            //save user data to localStorage and redirect to homepage
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.data)
            );
            //Save user data in indexeddb
            setUser(response.data.data);
            if (ricordami.value) {
              localStorage.setItem("ricordami", `${ricordami.value}`);
            }
            email.value = "";
            password.value = "";
            ricordami.value = false;
            loading.value = false;
            router.push("/tabs/");
          } else {
            openToast("Login fallito, controlla email e password", "danger");
          }
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    return {
      email,
      password,
      doLogin,
      ricordami,
      loading,
      //spinner login
      //isOpenRef,
      setOpen,
    };
  },
});
