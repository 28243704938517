import { toastController } from '@ionic/vue';

//Toast to show if login isn't successfull
export async function openToast(message, color) {
  const toast = await toastController.create({
    message: message,
    duration: 2500,
    color: color
  });
  return toast.present();
}


