import Localbase from 'localbase';

export const dbUser = new Localbase("user");

export const dbCustomers = new Localbase("clienti");
dbCustomers.config.debug = false;

export const dbTickets = new Localbase("tickets");


//Add user to user collection
export async function setUser(user) {
    await dbUser.collection('user').add(user, user.users_id)
    //console.log('user added!')
}
//Add all customers to clienti collection
export function setCustomers(customers) {
    dbCustomers.collection('clienti').add(customers, customers.customers_id);
    //console.log('customers added');
}
//Add all customers to clienti collection
export function setTickets(tickets) {
    dbTickets.collection('tickets').add(tickets, tickets.tickets_id);
    //console.log('tickets added');
}


//Get all customers from clienti collection
export async function getCustomers() {
    try {
        const customers = await dbCustomers.collection('clienti').get();
        //console.log('customers from indexeddDB: ', customers)
        return customers;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get customer by customers_id
export async function getCustomer(id) {
    try {
        const customer = await dbCustomers.collection('clienti').doc({ customers_id: id }).get();
        //console.log('customers: ', customer)
        return customer;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get ticket fot this user
export async function getTickets() {
    try {
        const tickets = await dbTickets.collection('tickets').collection('tickets').get();
        //console.log('tickets: ', tickets)
        return tickets;
    }
    catch (error) {
        console.log('error: ', error)
    }
}